import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";
import "./case-studies.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

import imgBrickets from "../images/gfx/article-brickets-small.jpg"
import imgLocalAuthority from "../images/gfx/article-local-authority-small.jpg"
import imgBoardingNotification from "../images/gfx/article-boarding-notification-small.jpg"
import imgSchoolBusDisruption from "../images/gfx/article-keep-parents-schools-happy-during-school-bus-disruption-small.jpg"
import imgParentNotListening from "../images/gfx/article-why-parents-dont-use-your-school-bus.jpg"
import imgFuelPump from "../images/gfx/article-tech-save-fuel-on-school-bus-small.jpg"
import imgTraditionalPassesArticle from "../images/gfx/article-traditional-school-bus-passes-vs-mobile-tickets-small.jpg"
import imgSaveAdminArticle from "../images/gfx/article-ways-to-save-admin-school-services-small.jpg"
import imgStandingOrdersArticle from "../images/gfx/article-standing-orders-bad-for-school-bus-payments-small.jpg"
import imgHappyParentsArticle from "../images/gfx/article-keep-parents-happy-on-school-bus-small.jpg"
import imgFacemasksArticle from "../images/gfx/article-what-facemasks-mean-for-home-to-school-travel-small.jpg"
import imgCovidAffectsH2SArticle from "../images/gfx/article-how-covid-affects-home-to-school-travel-small.jpg"
import imgPSD2Article from "../images/gfx/article-psd2-sca-impact-coach-companies-small.jpg"
import imgDirectDebitArticle from "../images/gfx/article-direct-debit-shuttleid-small.jpg"

import Nav from "../components/nav"
import Contact from "../components/contact"
import Hero from "../components/hero-standard"
import Footer from "../components/footer";

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'sell'
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Our latest articles from the world of home-to-school transport" description="The latest articles from ShuttleID. If you operate school services then you'll probably find something interesting to read here!" />
        <Nav activeTab="blog" />
        <div>
            <div>

              <Hero h1="Blog" h2="Our latest articles from the world of home-to-school transport" />

              <div className='packages'>
                <div style={{ color: '#333' }}>
                  <div>
                    <div className='case-studies-parent'>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/local-authority-free-trial/'><img src={imgLocalAuthority} alt="Students boarding school bus" /></Link></p>
                          <h3><Link to='/local-authority-free-trial/'>Local authority package and free trial now available</Link></h3>
                          <p>ShuttleID has launched a new package designed specifically for local authorities. The package includes a 90-day free trial.</p>
                          <Link to='/local-authority-free-trial/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/brickets-school-bus-tickets-on-brick-phone/'><img src={imgBrickets} alt="Students boarding school bus" /></Link></p>
                          <h3><Link to='/brickets-school-bus-tickets-on-brick-phone/'>Brickets: School bus tickets on brick phones</Link></h3>
                          <p>With growing concerns about the impact of smartphones on children, schools and parents are turning to an alternative: "brick" phones</p>
                          <Link to='/brickets-school-bus-tickets-on-brick-phone/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/how-to-keep-parents-schools-informed-during-school-bus-disruption/'><img src={imgSchoolBusDisruption} alt="Students boarding school bus" /></Link></p>
                          <h3><Link to='/how-to-keep-parents-schools-informed-during-school-bus-disruption/'>How to keep parents and schools informed during school bus disruption</Link></h3>
                          <p>School bus disruption is inevitable but poor communication is unforgivable. Here's how to fix that.</p>
                          <Link to='/how-to-keep-parents-schools-informed-during-school-bus-disruption/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/school-bus-boarding-notifications-myths-busted/'><img src={imgBoardingNotification} alt="Students boarding school bus" /></Link></p>
                          <h3><Link to='/school-bus-boarding-notifications-myths-busted/'>Boarding notifications: Gimmick or lifesaver?</Link></h3>
                          <p>Are boarding notifications on school buses all hype and no substance? We share our findings from parents using the technology.</p>
                          <Link to='/school-bus-boarding-notifications-myths-busted/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/why-parents-dont-want-to-use-your-school-bus/'><img src={imgParentNotListening} alt="Parent not listening" /></Link></p>
                          <h3><Link to='/why-parents-dont-want-to-use-your-school-bus/'>Why parents don't want to use your school bus</Link></h3>
                          <p>How to deal with common parent objections to maximise utilisation and increase revenue on your school bus.</p>
                          <Link to='/why-parents-dont-want-to-use-your-school-bus/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/how-technology-can-save-fuel-on-your-school-bus/'><img src={imgFuelPump} alt="Fuel pump" /></Link></p>
                          <h3><Link to='/how-technology-can-save-fuel-on-your-school-bus/'>How technology can save fuel on your school bus</Link></h3>
                          <p>Our guide on how simple technology you can implement today can help save you money and energy on your school bus services.</p>
                          <Link to='/how-technology-can-save-fuel-on-your-school-bus/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="400" className='case-studies__text'>
                          <p><Link to='/traditional-school-bus-passes-vs-mobile-tickets/'><img src={imgTraditionalPassesArticle} alt="Applegates Coaches using ShuttleID" /></Link></p>
                          <h3><Link to='/traditional-school-bus-passes-vs-mobile-tickets/'>Traditional school bus passes vs mobile tickets</Link></h3>
                          <p>Our review of how popular mobile ticketing stands up against the traditional option of plastic photo IDs on school services.</p>
                          <Link to='/traditional-school-bus-passes-vs-mobile-tickets/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="500" className='case-studies__text'>
                          <p><Link to='/10-ways-to-save-admin-home-to-school-services/'><img src={imgSaveAdminArticle} alt="Driver using ShuttleID" /></Link></p>
                          <h3><Link to='/10-ways-to-save-admin-home-to-school-services/'>10 ways to save admin on your school services</Link></h3>
                          <p>Ten ways you can dial up the efficiency on your home-to-school operation in a way that will satisfy customers and save you time.</p>
                          <Link to='/10-ways-to-save-admin-home-to-school-services/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="600" className='case-studies__text'>
                          <p><Link to='/8-reasons-standing-orders-are-bad-for-business/'><img src={imgStandingOrdersArticle} alt="Cambridge Buses using ShuttleID" /></Link></p>
                          <h3><Link to='/8-reasons-standing-orders-are-bad-for-business/'>8 reasons standing orders are bad for business</Link></h3>
                          <p>Still using standing orders to collect school bus payments? We uncover why it's costing your business more than you think.</p>
                          <Link to='/8-reasons-standing-orders-are-bad-for-business/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/7-ways-to-keep-parents-happy-on-your-school-services/'><img src={imgHappyParentsArticle} alt="Cambridge Buses using ShuttleID" /></Link></p>
                          <h3><Link to='/7-ways-to-keep-parents-happy-on-your-school-services/'>How to keep parents happy on your school services</Link></h3>
                          <p>Concerned about increasing competition from operators and parents car sharing? Here's 7 ways to add value and wow parents.</p>
                          <Link to='/7-ways-to-keep-parents-happy-on-your-school-services/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="400" className='case-studies__text'>
                          <p><Link to='/what-face-covering-facemasks-mean-for-home-to-school-transport/'><img src={imgFacemasksArticle} alt="Cambridge Buses using ShuttleID" /></Link></p>
                          <h3><Link to='/what-face-covering-facemasks-mean-for-home-to-school-transport/'>What face masks mean for home-to-school transport</Link></h3>
                          <p>Our predictions on the lasting impact that face masks bring to passengers using your school services [May 2020].</p>
                          <Link to='/what-face-covering-facemasks-mean-for-home-to-school-transport/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="500" className='case-studies__text'>
                          <p><Link to='/7-ways-coronavirus-could-affect-home-to-school-transport/'><img src={imgCovidAffectsH2SArticle} alt="Cambridge Buses using ShuttleID" /></Link></p>
                          <h3><Link to='/7-ways-coronavirus-could-affect-home-to-school-transport/'>7 ways COVID could affect home-to-school transport</Link></h3>
                          <p>Industry opinion and issues that have the potential to impact home-to-school transport in the long term [Apr 2020].</p>
                          <Link to='/7-ways-coronavirus-could-affect-home-to-school-transport/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="600" className='case-studies__text'>
                          <p><Link to='/is-your-coach-company-ready-for-psd2-sca/'><img src={imgPSD2Article} alt="Cambridge Buses using ShuttleID" /></Link></p>
                          <h3><Link to='/is-your-coach-company-ready-for-psd2-sca/'>Is your coach company ready for PSD2/SCA?</Link></h3>
                          <p>Strong Customer Authentication is set to shake up the way online payments work. We analyse how this could impact coach companies.</p>
                          <Link to='/is-your-coach-company-ready-for-psd2-sca/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className='case-studies'>
                        <div data-aos="fade-up" data-aos-duration="300" className='case-studies__text'>
                          <p><Link to='/direct-debit-arrives-shuttleid/'><img src={imgDirectDebitArticle} alt="Cambridge Buses using ShuttleID" /></Link></p>
                          <h3><Link to='/direct-debit-arrives-shuttleid/'>Direct debit arrives on ShuttleID</Link></h3>
                          <p>Parents are now able to pay for school bus passes with the flexibility of Direct Debit through our ticketing platform. Read on to find out how it works.</p>
                          <Link to='/direct-debit-arrives-shuttleid/' className='btn'>
                            Read article <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
